import type { FC } from 'react';

import { Box, Fade, Typography } from '@mui/material';
import { clsx } from 'clsx';

import SandboxHeaderSvg from './sandbox_header.svg';
import { useSandboxContext } from 'shared/lib/sandbox/useSandboxContext';

type SandboxHeaderProps = {
  absolute?: boolean;
};

export const SandboxHeader: FC<SandboxHeaderProps> = ({ absolute }) => {
  const { isSandboxOn } = useSandboxContext();

  if (!isSandboxOn) {
    return null;
  }

  return (
    <Fade in={isSandboxOn} timeout={600}>
      <div
        className={clsx('pointer-events-none flex w-full flex-col items-center', {
          'absolute z-20': absolute,
        })}
        data-testid="sandbox-header"
      >
        <div className="h-0.5 w-full min-w-[197px] bg-primary-main" />
        <Box
          className="mt-[-1px] flex h-[32px] w-[158px] items-center justify-center bg-no-repeat"
          sx={{ backgroundImage: `url("${SandboxHeaderSvg}")` }}
        >
          <Typography className="whitespace-nowrap font-medium text-white" variant="body2">
            Sandbox mode
          </Typography>
        </Box>
      </div>
    </Fade>
  );
};
