import { useContext } from 'react';

import { SandboxContext } from '../../contexts/SandboxContext';

export const useSandboxContext = () => {
  const context = useContext(SandboxContext);

  if (context === undefined) {
    throw new Error('useSandboxContext must be used within a SandboxContextProvider');
  }

  return context;
};
