import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';

import type { PromoConfig } from 'hooks/promo';

export type FeaturesResponse = {
  promo?: PromoConfig[];
  flags?: {
    emulator_data_source_tax?: boolean;
    emulator_data_source_docs?: boolean;
    sandbox_switch_individual_company?: boolean;
    create_order_dialog?: boolean;
    paygo_fullstory_sample_rate?: number;
    intercom?: boolean;
  };
};

export const useFeatures = (enabled = true) => {
  return useQuery({
    queryKey: ['getFeatures'],
    queryFn: () => apiClient.getAnalyticsSource<FeaturesResponse>('features', {}),
    retry: false,
    enabled,
  });
};
