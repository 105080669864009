import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from '@truvhq/ui';
import type { Theme } from '@truvhq/ui';
import { useHistory } from 'react-router-dom';

import { AnalyticsButton } from 'components/AnalyticsButton';
import Logo from 'components/Logo';

import { LogoutIcon } from 'assets/icons';

import { useAuth } from 'shared/lib/auth/useAuth';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    width: '832px',
    padding: '60px 0',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      minWidth: '360px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  button: {
    color: colors.grey70,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontSize: '16px',
    whiteSpace: 'nowrap',
  },

  poweredBy: {
    display: 'flex',
    alignItems: 'center',
    color: colors.grey70,
    fontSize: '16px',
    fontFamily: theme.typography.fontFamily,
    padding: '12px 8px',
    marginLeft: '-8px',
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    width: '20px',
    height: '20px',
  },
  logOut: {
    width: '110px',
    marginRight: '-8px',
  },

  logo: {
    marginTop: -2,
    marginLeft: 5,
    height: '14px',
  },
}));

type FooterProps = {
  pageName: string;
};

export const Footer = ({ pageName }: FooterProps) => {
  const { logout } = useAuth();
  const history = useHistory();
  const classes = useStyles();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box className={classes.footer}>
      <Box className={classes.poweredBy}>
        Powered by <Logo className={classes.logo} size="xsmall" />
      </Box>
      <Box className={classes.logOut}>
        <AnalyticsButton
          className={classes.button}
          color="inherit"
          eventName={`${pageName} - click logout`}
          onClick={handleLogout}
        >
          <LogoutIcon className={classes.icon} />
          <span>Log out</span>
        </AnalyticsButton>
      </Box>
    </Box>
  );
};
