import { useEffect } from 'react';

import Intercom, { shutdown } from '@intercom/messenger-js-sdk';

import { useFeatures } from 'hooks/features';
import { useAuth } from 'shared/lib/auth/useAuth';

export const IntercomChat = () => {
  const { isAuthenticated, user } = useAuth();
  const { data: features } = useFeatures(isAuthenticated);
  const hasIntercom = !!features?.flags?.intercom;

  useEffect(() => {
    if (!isAuthenticated || !user || !import.meta.env.VITE_APP_INTERCOM_KEY || !hasIntercom) {
      return;
    }

    Intercom({
      app_id: import.meta.env.VITE_APP_INTERCOM_KEY,
      user_id: user.profile?.id || user.id,
      email: user.profile?.email || user.email,
      company: user?.company,
    });

    return () => {
      shutdown();
    };
  }, [isAuthenticated, user, hasIntercom]);

  return null;
};
