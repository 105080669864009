import type { FC, PropsWithChildren } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';

import omitBy from 'lodash-es/omitBy';
import throttle from 'lodash-es/throttle';
import { useHistory, useRouteMatch } from 'react-router-dom';

import type { EnvType } from 'api/types';

import { encodeQueryParams } from 'utils/queryParams';

import { useQueryParams } from 'hooks/useQueryParams';

import type { Filters } from '../lib/filters/common';
import { defaultFilters } from '../lib/filters/common';
import { mergeFilters } from '../lib/filters/mergeFilters';
import { useSandboxContext } from '../lib/sandbox/useSandboxContext';
import { urls } from '../urls';

const STORAGE_FILTERS_KEY = 'common_filters';

type FiltersContextType = {
  filters: Filters;
  filtersQuery: string;
  updateFilters: (filters: Partial<Filters>) => void;
  resetFilters: () => void;
};

let storageFilters: Filters = {
  ...defaultFilters,
};

const possibleEnvs: EnvType[] = ['prod', 'dev', 'sandbox'];

try {
  const storageValue = sessionStorage.getItem(STORAGE_FILTERS_KEY);

  const filters: Filters = JSON.parse(storageValue as string);

  if (filters.env && Array.isArray(filters.env) && filters.env.every((item) => possibleEnvs.includes(item))) {
    storageFilters.env = filters.env;
  }

  if (filters.created_at__gte && Date.parse(filters.created_at__gte)) {
    storageFilters.created_at__gte = new Date(filters.created_at__gte).toISOString();
  }

  if (filters.created_at__lte && Date.parse(filters.created_at__lte)) {
    storageFilters.created_at__lte = new Date(filters.created_at__lte).toISOString();
  }

  // eslint-disable-next-line no-empty
} catch (e) {}

const defaultContextValue: FiltersContextType = {
  filters: storageFilters,
  filtersQuery: encodeQueryParams(storageFilters),
  updateFilters: () => {},
  resetFilters: () => {},
};

export const FiltersContext = createContext(defaultContextValue);

export const FiltersContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isSandboxOn } = useSandboxContext();
  const history = useHistory();

  const queryParams = useQueryParams<{
    env?: string | string[];
    created_at__gte?: string;
    created_at__lte?: string;
    query?: string;
  }>();

  const { env: queryParamsEnv, created_at__gte, created_at__lte, query } = queryParams;
  const env = !!queryParamsEnv && ((Array.isArray(queryParamsEnv) ? queryParamsEnv : [queryParamsEnv]) as EnvType[]);

  const [filters, setFilters] = useState<Filters>({
    env: env && env.every((item) => possibleEnvs.includes(item)) ? env : storageFilters.env,
    created_at__gte: created_at__gte || storageFilters.created_at__gte,
    created_at__lte: created_at__lte || storageFilters.created_at__lte,
    query: query ?? '',
  });

  const filtersQuery = useMemo(() => {
    return encodeQueryParams(filters);
  }, [filters]);

  const updateFilters = throttle((partialFilters: Partial<Filters>) => {
    const newFilters = mergeFilters(filters, partialFilters);

    try {
      const { query: _, page, page_size, ...filtersToCache } = newFilters;

      sessionStorage.setItem(STORAGE_FILTERS_KEY, JSON.stringify(filtersToCache));
      // eslint-disable-next-line no-empty
    } catch (e) {}

    setFilters(newFilters);

    const otherQueryParams = omitBy(queryParams, (_, key) => Object.keys(newFilters).includes(key));

    const search = encodeQueryParams({ ...newFilters, ...otherQueryParams });

    history.replace({ search });
  }, 500);

  const resetFilters = () => {
    storageFilters = { ...defaultFilters };

    const otherQueryParams = omitBy(queryParams, (_, key) => Object.keys(storageFilters).includes(key));

    history.replace({ search: encodeQueryParams(otherQueryParams) });
    sessionStorage.removeItem(STORAGE_FILTERS_KEY);
  };

  const matchNoFiltersPage = useRouteMatch({
    path: [urls.home.url(), urls.verification.url(), urls.logs.url(), urls.templates.url(), urls.emulator.url()],
  });

  useEffect(() => {
    if (isSandboxOn != null) {
      const env: EnvType[] = isSandboxOn ? ['sandbox'] : ['dev', 'prod'];

      if (matchNoFiltersPage) {
        return;
      }

      if (env.some((item) => !filters.env.includes(item))) {
        updateFilters({ env });
      }
    }
  }, [isSandboxOn, updateFilters, matchNoFiltersPage]);

  return (
    <FiltersContext.Provider value={{ filters, filtersQuery, updateFilters, resetFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};
