import type { FC, ReactNode } from 'react';

import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Logo, LogoutIcon } from 'assets/icons';

import { useAuth } from 'shared/lib/auth/useAuth';

export const FullScreenContentLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { logout } = useAuth();

  return (
    <div className="relative flex h-full w-full items-center justify-center">
      <div className="absolute left-6 top-6">
        <Link to="/">
          <Logo className="h-5 w-auto text-grey100" />
        </Link>
      </div>
      <Button className="group absolute bottom-6 left-6 p-0" color="inherit" onClick={() => logout()}>
        <LogoutIcon className="mr-3 text-grey60 group-hover:text-grey70 group-active:text-grey80" />
        <Typography className="text-grey70 group-hover:text-grey80 group-active:text-grey90">Log out</Typography>
      </Button>
      {children}
    </div>
  );
};
