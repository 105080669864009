import 'react-perfect-scrollbar/dist/css/styles.css';
import 'app/styles/style.css';
import { createRoot } from 'react-dom/client';

import App from 'app/App';
import * as serviceWorker from 'app/serviceWorker';

const container = document.getElementById('root');

if (!container) {
  throw new Error('Container not found');
}

const root = createRoot(container);

root.render(<App />);

serviceWorker.unregister();
