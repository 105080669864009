import { Box, Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@truvhq/ui';
import { LoadingScreen } from '@truvhq/ui';

import { useGetCompany } from 'hooks/company';
import { useGetWidgetSettings } from 'hooks/customization';

import Logo from 'components/Logo';
import { Footer } from 'components/UnverifiedEmail/Footer';

import { useAuth } from 'shared/lib/auth/useAuth';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    width: '832px',
    padding: '32px 28px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      minWidth: '360px',
    },
  },
}));

export type UnapprovedCompanyMemberProps = {
  declined: boolean;
};

export const UnapprovedCompanyMember = ({ declined }: UnapprovedCompanyMemberProps) => {
  const { user } = useAuth();
  const classes = useStyles();
  const { data: settings, isPending: areSettingsLoading } = useGetWidgetSettings();
  const { data: company, isFetching: isCompanyFetching } = useGetCompany();

  if (areSettingsLoading || isCompanyFetching) {
    return <LoadingScreen />;
  }

  const companyName = user?.company?.name || company?.name;

  return (
    <div className="flex h-full grow flex-col">
      <Grid
        container
        alignItems="center"
        className="h-full grow"
        direction="column"
        justifyContent="center"
        spacing={0}
      >
        <Card className={classes.container}>
          {settings?.logo && <Logo className="object-contain" size="large" src={settings?.logo} />}
          <Box mt={settings?.logo ? 4 : 0}>
            <Typography variant="h1">
              {declined ? 'Your account request has been declined' : 'Your account is now waiting for approval'}
            </Typography>
            <Typography className="mt-2 px-8" variant="body1">
              {declined
                ? `${companyName} admin has declined your application. If you have any questions about your application please reach out to: ${companyName} ${company?.billing_email}.`
                : `Once administrator at ${companyName} reviews your request, you'll be notified.`}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Footer pageName="unapproved user" />
    </div>
  );
};
